import { Button, Typography, Box } from "@mui/material";
import back from "../../assets/svg/Back-arrow.svg";
import user from "../../assets/svg/user.svg";
import description from "../../assets/svg/descritpion.svg";
import option from "../../assets/svg/option-3-lines-with-dots.svg";
import recycle from "../../assets/svg/recycle.svg";
import exp from "../../assets/svg/export.svg";
import DataCard from "../../components/cards/dataCard";
import { AdminCardDataInterface } from "../../_utils/interface";
import { AssessmentHeaderProps } from "../../_utils/interface";
import { Link } from "react-router-dom";
import axios from "axios";
import { ApiConstants } from "_utils/api-constants";
import axiosHttp from "_utils/axios.index";

export default function AssessmentHeader(props: AssessmentHeaderProps) {
  const {
    title,
    numberofQues,
    averageAttempts,
    averageMarks,
    total_submission,
    id,
    totalMarks,
  } = props;
  const cardData: AdminCardDataInterface[] = [
    {
      title: "Number of Students",
      icon: user,
      tooltip: "Total number of students who has taken assessment.",
      data: total_submission,
    },
    {
      title: "Average Marks",
      icon: description,
      tooltip: "Average marks scored by the students.",
      data:
        averageMarks === null
          ? "N/A"
          : `${Math.round(averageMarks)}/${props.totalMarks}`,
    },
    {
      title: "No. of Questions",
      icon: option,
      tooltip: "Total number of question in the assessment.",
      data: numberofQues === null ? "N/A" : numberofQues.toString(),
    },
    {
      title: "Average Attempts",
      icon: recycle,
      tooltip: "Average number of attempts taken by each student.",
      data: averageAttempts ? `${averageAttempts.toFixed(2)}` : "N/A",
    },
  ];

  return (
    <div className="mb-10 mt-[-6vh] lg:mt-0 lg:p-5">
      <Box className="flex justify-between items-center p-4">
        <Link to="/manage-assessment">
          <div className="flex items-center text-[5px]">
            <img src={back} className="h-[24px] mr-2" alt="" />
            <Typography
              variant="h4"
              sx={{
                color: "#001F68",
                fontSize: { lg: "32px", sm: "28px", xs: "5vw" },
                fontWeight: "600",
              }}
            >
              {title}
            </Typography>
          </div>
        </Link>
        {/* add custom link fro csv */}
        <div className="flex md:flex-row flex-col">
          <a
            className="mr-2 border border-[#3183FF] text-[#3183FF] w-full leading-[16px] inline-flex items-center justify-center px-4 py-2 rounded-lg text-sm font-medium"
            target="_blank"
            rel="noreferrer noopener  nofollow "
            href={`http://backend.codingjudge.com/api/assessment_V2/admin/export-graded-assessments?test_id=${id}`}
          >
            Export CSV
            <img src={exp} className="ml-1" alt="" />
          </a>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3183FF",
              width: "100%",
              mt: { xs: "8px", lg: "0px" },
              lineHeight: "16px",
            }}
          >
            Edit Assessment
          </Button>
        </div>
      </Box>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 mb-3 mx-3 xs:grid-cols-1">
        {cardData &&
          cardData.map((single, index) => (
            <div
              key={index}
              className="shadow-[#D7E8FF] shadow rounded-xl border-[#D7E8FF]"
            >
              <DataCard key={index} {...single} />
            </div>
          ))}
      </div>
    </div>
  );
}
